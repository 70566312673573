import React from 'react';
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Sobre from './pages/Sobre';
import Transparencia from './pages/Transparencia';
import Iframe from 'react-iframe';
import logo from './svg/logo.svg';
import './styles/App.css';

export default class App extends React.Component {
  render() {
    return (
      <>
        <Router>
          <header>
            <div id="fb-root"></div>
            <script
              async
              defer
              crossOrigin="anonymous"
              src="https://connect.facebook.net/pt_BR/sdk.js#xfbml=1&version=v9.0"
              nonce="1TwQbKv6"
            ></script>

            <div className="divHeader">
              <div>
                <Link to="/">
                  <img src="Material Grafico/Logo_Grande_Col_Hor - Cópia.jpg" alt="Logo APAE" className="logo" />
                </Link>
              </div>

              <div className="menu">
                <Link to="/" className="menu_item">
                  Home
                </Link>
                <Link to="/Transparencia" className="menu_item">
                  Transparência
                </Link>
                <Link to="/sobre" className="menu_item">
                  Sobre
                </Link>
                <a
                  href="https://www.paypal.com/donate/?hosted_button_id=9M55EEWXMWWVW"
                  target="_blank"
                  className="menu_item"
                >
                  Doações
                </a>
              </div>
            </div>
          </header>

          <article>
            <Routes>
              <Route path="/sobre" element={<Sobre />} />
              <Route path="/Transparencia" element={<Transparencia />} />
              <Route path="/" element={<Home />} />
            </Routes>
          </article>

          <footer>
            <div className="footer_container">
              <div className="footer_info">
                <div className="face_pag">
                  <Iframe
                    src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fapaebb%2F&tabs&width=340&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=false&appId"
                    width="340"
                    height="130"
                    style={{ border: 'none', overflow: 'hidden' }}
                    scrolling="no"
                    frameBorder="0"
                    allowFullScreen={true}
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                  ></Iframe>
                </div>

                <div className="address">
                  <div className="divAddress">
                    Rodovia Municipal Campo Místico, Bueno Brandão - MG, 37578-000
                    <img src="Material Grafico/icons8_marker_48px.png" alt="icons" className="markerIcon" />
                  </div>

                  <div className="divTell">
                    Telefone: (35) 3463-1352
                    <img src="Material Grafico/icons8_call_48px.png" alt="icons" className="callIcon" />
                  </div>
                </div>
              </div>

              <div className="divFelp">
                <h3>Desenvolvido por:</h3>
                <img src={logo} alt="logo" className="logoFelp" />
                <div className="felpDesc">2023 - APAE BUENO BRANDÃO</div>
              </div>
            </div>
          </footer>
        </Router>
      </>
    );
  }
}
