import React from 'react'
import '../styles/Sobre.css'
import Sobre_Capa from '../svg/sobre_capa.svg'
import Sobre_frente from '../svg/sobre_frente.svg'

const Sobre = () => {
    return (
        <>
            <div class="center">
                <h1>Sobre nós</h1>
                <p>
                    A Associação de Pais e Amigos dos Excepcionais de Bueno Brandão, mantenedora da Escola da Fraternidade e
                    Educação Especial, surgiu do esforço de pessoas de vários segmentos da sociedade como o Comandante Wanderley
                    Aparecido do Nascimento, os pais Márcia Antônia Lucas, Suelene Almeida, "Tiana do Messias", Maria Orlanda
                    Bueno e outros, buscando atendimento especializado aos que necessitavam da educação especial e, muitas vezes
                    estavam excluídos de nossa sociedade.
                </p>
                <p>
                    Sua criação foi em 27 de outubro de 1990, no entanto a inauguração e o funcionamento só aconteceram no ano
                    seguinte, com o apoio da Prefeitura Municipal e o prefeito da época Dr. Cleudes Antônio Chíco que cedeu o
                    prédio, equipou a entidade e firmou um convênio com repasses em dinheiro e de funcionário (professores,
                    motorista, especialistas, merenda e outros). Atualmente contamos com o repasse de uma psicóloga (jornada de
                    8 horas semanais), uma fonoaudióloga (jornada de 12 horas semanais), uma dentista (jornada de 4 horas
                    semanais), uma assistente social (jornada de 4 horas semanais), um repasse de R$ 2.000,00 (dois mil reais
                    mensais), merenda escolar e transporte escolar.
                </p>
            </div>

            <div class="imgSobre">
                <img src={Sobre_Capa} alt="Foto de alunos e colaboradores da APAE BUENO BRANDÂO" />
            </div>

            <div class="center">
                <p>
                    Desde a sua criação a APAE funcionou por vários anos em seu antigo endereço, na Avenida Bom Jesus, atual
                    Telecentro. Ao longo desses anos a APAE alcançou conquista burocráticas: Utilidade Pública Municipal,
                    Estadual e Federal, cadastro na Secretaria do Trabalho e Ação Social, Cadastro no Conselho Nacional e
                    Municipal de Assistência Social e também Certificado de Entidade Beneficente de Assistência Social. Com a
                    ajuda do Sr. Domingos, na época gerente do Banco do Brasil, a APAE consegue a Filiação na Federação Nacional
                    das APAEs. Alcançou também conquistas materiais, como a doação do terreno pelo Sr. Mauro Carneiro da Luz
                    aonde foi construída a atual sede da APAE situada a Rodovia Municipal Campo Místico.
                </p>
                <p>
                    Esta nova sede teve seu pontapé inicial com o incentivo e ajuda do Dr. João Cláudio Teodoro, Juiz de Direito
                    na época e contou com a colaboração de toda comunidade bueno-brandense através de doações em dinheiro,
                    materiais e mão de obra e também inúmeros eventos realizados pelos funcionários e alguns membros da
                    Diretoria que não mediram esforços, sacrificando feriados, férias e finais de semana para atingir seus
                    objetivos.
                </p>
                <p>
                    Após a construção do segundo pavimento, graças ao imenso apoio do Padre José Raimundo do Prado e
                    companheiros, ajuda política e toda comunidade bueno-brandense, a entidade começou a funcionar. Até hoje o
                    prédio não está totalmente concluído: a área externa, de lazer e esportiva, ainda se encontra em forma de
                    projeto.
                </p>
            </div>

            <div class="imgSobre">
                <img src={Sobre_frente} alt="Foto sede da APAE BUENO BRANDÂO" />
            </div>

            <div class="center">
                <p>
                    São oferecidos atendimentos: educacional, fisioterápico, fonoaudiólogo, odontológico, psicológico e
                    recreativo (CRAS), além de já ter prestado serviços para toda comunidade oferecendo sua sala de fisioterapia
                    enquanto o município não possuía estrutura para esse serviço, e também consultório odontológico enquanto o
                    posto de saúde estava em reforma.
                </p>
                <p>
                    O objetivo maior está se tornando realidade: através de leis e conscientização da sociedade, pessoas com
                    deficiência estão conquistando sua autonomia e seus direitos, tornando-se assim cidadãos participativos e
                    incluídos socialmente. A entidade conquistou através da cota da empresa Danone S/A, emprego com carteira
                    assinada para seis alunos, estes prestam serviço na própria entidade. Essa luta apresentou muitos avanços e
                    em nossa entidade essa vitória só foi e continua sendo possível devido a muitas pessoas de bom coração que
                    são nossos associados, políticos, alguns comércios de nossa cidade, voluntários, alguns pais e amigos. A
                    luta continua, precisamos trazer todos os pais para nossa batalha. Fácil não é, mas também não é impossível,
                    porque "Sozinhos somos fracos, mas juntos seremos fortes na causa que abraçamos".
                </p>
            </div>

            <div class="txtFinal">
                <p>
                    Trabalho feito pelos alunos e equipe de profissionais que atuam na APAE de Bueno Brandão.
                </p>
                <p>Contribuia com nossa proposta</p>

                <div className='button_doar_container'>
                    <a href="https://www.paypal.com/donate/?hosted_button_id=9M55EEWXMWWVW">
                        <button className='button_doar'>
                            DOAR
                        </button>
                    </a>
                </div>
            </div>
        </>
    );
};

export default Sobre;